@import 'components/typography';
@import 'components/variables';
@import 'components/mixins';

.hero-text {
  font-size: 30px;
}

em {
  font-style: italic;
}

th {
  font-weight: bold;
}

.blog {
  padding-top: 75px;
  padding-bottom: 90px;
}

.blog img {
  margin-bottom: 20px;
  max-width: 100%;
  display: block;
}

.blog h1 {
  line-height: 1.2;
}

.blog h2 {
  font-style: italic;
  font-weight: normal;
  font-size: 1.75rem;
  line-height: 1.46;
}

.blog .date {
  font-size: 0.875rem;
  color: #666666;
}

.blog .addthis_sharing_toolbox {
  margin: 20px 0px 40px;
}

.blog .news-and-views-badge {
  margin-bottom: 30px;
}

log .content .news-and-views-badge {
  margin-top: 30px;
}

.blog p.small {
  font-size: 75%;
}

@media(min-width: 1100px) {
  .blog .news-and-views-badge {
    margin-bottom: 65px;
  }

  .blog .content .news-and-views-badge {
    margin-top: 70px;
    margin-bottom: 0px;
  }
}

@media(max-width: 1099px) {
  .blog {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .blog .stay-informed-callout-column {
    width: 100% !important;
  }

  .blog h2 {
    font-size: 1.5rem;
    line-height: 1.25;
  }
}

.home-testimonial {
  .testimonial-container {
    // letter-spacing: 1px;
    background: #2e537a;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-family: 'EB Garamond', serif;
      font-weight: 400;
      font-size: 20px;
      width: 80%;
      margin: 0 auto;
      text-align: center;
      font-style: italic;

      span {
        font-weight: 700;
        font-style: normal;
        font-family: 'Montserrat', sans-serif;
      }
    }

    // .testimonial-font {
    //     font-family: 'EB Garamond', serif;
    //     font-weight: 400;
    //     font-size: 20px;
    //     width: 80%;
    //     margin: 0 auto;
    //     text-align: center;
    //     font-style: italic;
    // }
  }
}

@media(max-width: 720px) {
  .home-testimonial {
    .testimonial-container {
      .testimonial-font {
        font-size: 16px;
        line-height: 1.2;
      }
    }
  }
}

.blog .stay-informed-callout {
  box-sizing: border-box;
  height: calc(100% - 30px);
  padding: 30px;
  margin-bottom: 30px;
  color: #444444;
  background-color: #DFDFDF;
  font-size: 1.3125rem;
  line-height: 1.43;
}

.blog .stay-informed-callout strong {
  display: block;
  margin-bottom: 30px;
  color: #00487C;
  font-family: Oswald;
  font-size: 2.25rem;
  letter-spacing: .05em;
  line-height: 1.41;
}

.blog .stay-informed-callout .button {
  margin-top: 75px;
}

@media(max-width: 1100px) {
  .blog .stay-informed-callout {
    font-size: 1.125rem;
    line-height: 1.43;
  }

  .blog .stay-informed-callout strong {
    font-size: 1.75rem;
    line-height: 1.41;
  }

  .blog .stay-informed-callout .button {
    margin-top: 30px;
  }
}

.settlement-loans-section {
  h2 {
    font-size: 3rem;
  }
}


.faq-list li+li {
  margin-top: 10px;
}

.sectionText {
  margin-bottom: 1rem;
}

ul {
  &.faq-list {
    padding: 0;

    li {
      list-style-type: none;
    }
  }
}

.faq-list h3 {
  position: relative;
  display: block;
  padding: 20px 40px 20px 20px;
  margin: 0px;
  font-family: "Fira Sans";
  font-size: 1.5rem;
  line-height: 1;
  font-weight: normal;
  color: #FFFFFF;
  background-color: #BFBFBF;
  -webkit-transition: background-color 150ms;
  transition: background-color 150ms;
  cursor: pointer;
}

.faq-list h3:after {
  position: absolute;
  top: 20px;
  right: 20px;
  content: url(/img/interfaces/icons/expand.svg);
  width: 25px;
  height: 25px;
  float: right;
}

.faq-list .expanded h3 {
  background-color: #00477C;
}

.faq-list .expanded h3:after {
  content: url(/img/interfaces/icons/collapse.svg);
}

.faq-list .faq-content {
  padding: 0px;
  background-color: #F2F2F2;
  overflow: hidden;
  max-height: 0px;
  font-weight: 300;
  line-height: 1.4;
}

.faq-list .expanded .faq-content {
  padding: 20px;
  max-height: 10000px;
}

.faq-content ul {
  margin: 20px 0px;
}

@media(min-width: 768px) {
  .padded {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media(min-width: 1200px) {
  .padded {
    padding-left: 100px;
    padding-right: 100px;
  }

  .padded.alternate {
    padding-top: 90px;
    padding-bottom: 90px;
    font-size: 1.125rem;
    line-height: 1.45;
  }
}

@media(min-width: 1400px) {
  .padded {
    padding-left: calc((100% - 1200px) / 2);
    padding-right: calc((100% - 1200px) / 2);
  }
}

.padded.main {
  padding-top: 75px;
  padding-bottom: 90px;
}

.padded.grey {
  background-color: #F2F2F2;
}

.padded.grey-border {
  border-bottom: 3px solid #F2F2F2;
}

.padded.blue {
  background-color: #2587c9;
  color: #FFF;
}

.padded.darker-blue {
  background-color: #0e74b5;
  color: #FFF;
}

.banner.has-navigation+.padded {
  padding-top: 75px;
}

.padded.center-content {
  padding-top: 90px;
  padding-bottom: 90px;
  background-position: center;
  background-size: cover;
  background-color: #5b6e7d;
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
}

.padded.center-content h2 {
  color: inherit;
  font-size: 3rem;
}

.padded.center-content .button {
  margin-top: 45px;
}

.center-content.solutions-for-lawyers-settlement-loans {
  background-image: url(/img/solutions/for-lawyers/settlement-loans/center-content.png);
}

.padded.main.solutions-for-lawyers-expert-access-features-and-benefits {
  background: url(/img/solutions/for-lawyers/expert-access/features-and-benefits.png) no-repeat center / cover;
}

.padded.main.solutions-for-lawyers-expert-access-how-it-works {
  text-align: center;
}

.solutions-for-lawyers-expert-access-our-expert-partners {
  font-size: 1.5rem;
}

.solutions-for-lawyers-expert-access-our-expert-partners .partners-image {
  width: 100%;
  position: relative;
  // padding-bottom: 100%;
}

.solutions-for-lawyers-expert-access-our-expert-partners .partners-image img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

@media(max-width: 767px) {
  .solutions-for-lawyers-expert-access-our-expert-partners .partners-image {
    margin-bottom: 20px;
  }
}

.padded.about {
  padding-top: 80px;
  padding-bottom: 90px;
}

.padded.the-bridgepoint-difference {
  background: url('/img/about/the-bridgepoint-difference.png') no-repeat center / cover;
}

.padded.the-bridgepoint-difference h2 {
  color: #FFF;
  font-size: 3rem;
  margin-bottom: 45px;
}

.padded.the-bridgepoint-difference .column>div {
  width: 100%;
  background-color: #FFFFFF;
  padding: 30px;
  box-sizing: border-box;
}

.padded.the-bridgepoint-difference h3 {
  position: relative;
  color: #00487C;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.75rem;
  font-weight: bold;
  letter-spacing: .1em;
  line-height: 1;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.padded.the-bridgepoint-difference h3:after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 60px;
  background-color: #E8D766;
}


@media(max-width: 1200px) {
  .padded.the-bridgepoint-difference h3 {
    font-size: 1.5rem;
  }
}

@media(max-width: 1100px) {
  .padded.the-bridgepoint-difference .column {
    margin-top: 20px;
  }
}

@media(max-width: 1024px) {

  .padded.main,
  .padded.center-content,
  .padded.about,
  .banner.partners,
  .padded.testimonials,
  .padded.careers {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .banner.has-navigation+.padded {
    padding-top: 70px;
  }
}

@media(max-width: 767px) {
  .banner.has-navigation+.padded {
    padding-top: 45px !important;
    @at-root{
      .banner.has-navigation.homepage+.padded.hero-cta-cards{
        padding-top:0 !important;
      }
    }
  }
}

.horizontal-key-line {
  padding-bottom: 45px;
  margin-bottom: 45px;
  border-bottom: 3px solid #E8D766;
}

@media(max-width: 1100px) {
  .horizontal-key-line {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

@media(max-width: 767px) {
  .horizontal-key-line {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
}

.button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  min-width: 180px;
  height: 40px;
  padding: 12px 30px;
  border: 2px solid #17446A;
  border-radius: 20px;
  color: #00447E;
  background: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
  letter-spacing: .1em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  font-style: normal;
  white-space: nowrap;
  cursor: pointer;
  transition:.22s;

}

.button.solid {
  background: #00447E;
  color: #FFF;
  font-weight: bold;

  &:hover {
    background: #fff;
    color: $blue;
    transition: .5s all;
  }
}

.button.white {
  border-color: #FFF;
  color: #FFF;
}

.button.white.solid {
  background: #FFF;
  color: $blue;
}

.button.yellow {
  border-color: $yellow;
  color: $yellow;
}

.button.yellow.solid {
  background: $yellow;
  color: $blue;
  &:hover{
    background: darken($yellow, 10%);
  }
}


.button.blue {
  border-color: $blue;
  color: $blue;

  &:hover {
    background: $blue;
    color: #fff;
    transition: .5s all;
  }
}
.button.blue.solid {
  background: $blue;
  color: $white;
  &:hover{
    background: darken($blue, 10%);
  }
}

.button.lt-gray {
  border-color: $lightGray;
  color: $lightGray;
}

.button.btnblue {
  border-color: $blue;
  background-color: $blue;
  color: $white;

  &:hover {
    background-color: transparent;
    color: $blue;
    border-color: $blue;
  }
}


@media(max-width: 767px) {
  .button {
    width: 100%;
    height: 50px;
    padding: 15px;
    border-radius: 25px;
    font-size: 1rem;
  }
}

.link {
  color: #00447E;
  text-decoration: underline;
}

.link.same-color {
  color: inherit;
}

.link.no-underline {
  text-decoration: none;
}

.other-link {
  display: inline-block;
  padding-right: 30px;
}

.social-link {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-top: -3px;
  font-size: 0;
  background: url(/img/interfaces/icons/social.svg) no-repeat center / 98px 14px;
  vertical-align: top;
}

.social-link.facebook {
  width: 13px;
  background-position: 3px center;
}

.social-link.twitter {
  width: 15px;
  background-position: -20px center;
}

.social-link.linkedin {
  width: 16px;
  background-position: -48px center;
}

.social-link.google-plus {
  width: 15px;
  background-position: -76px center;
}


/* inputs */

.input {
  display: block;
  width: 100%;
  background-color: #FFF;
  color: #444444;
  font-family: "Fira Sans";
  font-size: 16px;
  font-weight: 300;
  line-height: 1.625;
}

.input input[type="text"],
.input input[type="email"],
.input input[type="number"],
.input select,
.input textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: inherit;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 0px 15px;
}

.input textarea {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 225px;
  display: block;
  resize: none;
}

.input.select {
  background: #FFF url(/img/interfaces/icons/input-select-arrow.svg) no-repeat right center / 44px 24px;
}

.input.select select {
  padding-right: 53px;
}

.input.select.filter {
  margin-top: 30px;
  background-color: #F2F2F2;
  color: #00447E;
  background-image: url(/img/interfaces/icons/filter-select-arrow.svg);
  color: #00487C;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.grey .input.select.filter {
  background-color: #FFF;
}

.input.select.filter select {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@media(max-width: 767px) {
  .input {
    font-size: 18px;
  }

  .input input[type="text"],
  .input input[type="email"],
  .input input[type="number"],
  .input select,
  .input textarea {
    height: 50px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}


#form-input-processedDate {
  display: none;
}

/* banners */

.banner {
  background-size: cover;
  background-position: center;
  background-color: #17446A;
  color: #F8F8F8;
  font-size: 2.125rem;
  font-weight: 300;
  line-height: 1.41;
}

.banner.alternate {
  color: #FFFFFF;
  font-size: 1.4375rem;
  line-height: 1.5;
  font-weight: 400;
}

.banner.grey {
  color: #444444;
}

.banner.blue {
  font-size: 1.25rem;
}

.banner.blue p {
  margin-top: 50px;
}

.banner.has-navigation {
  position: relative;
  font-size: 1.3125rem;
  line-height: 1.5;
}

.banner.law-firms {
  background-image: url(/img/law-firms/banner.png);
}
.banner {
  position: relative;
}



  .hero-content {
    display: flex;
    flex-direction: column;
    @include mq($small) {
      padding-top: 170px;
    }
    .hero-text-container {
      order: 1;
      @include mq($small) {
        order: 2;
      }
    }
    // >h1 {
    //   order: 1;
    //   @include mq($small) {
    //     order: 2;
    //   }
    // }
    // >p {
    //   order: 2;
    //   @include mq($medium) {
    //     order: 3;
    //   }
    // }
    .support {
      order: 2;
      display: flex;
      align-items: center;
      margin-top: 30px;
      @include mq($small) {
        order: 1;
        margin: 0;
      }
      .text-container {
        order: 2;
        width: 240px;
        @include mq($small) {
          order: 1;
        }
        p {
          font-size: 13px;
          margin-left: 10px;
          @include mq(350px) {
            font-size: 14px;
          }
          @include mq($small) {
            margin: 0;
          }
        }
      

      }
      .image-container {
        order: 1;
        @include mq($small) {
          order: 2;
        }
        
      }

      @include mq($small) {
        order: 1;

        justify-content: flex-end;

      }
    }
    &.disbursement-financing {
      background: #0F74B6;
      position: relative;

      .hero-text-container {
        width: 100%;
        @include mq($small) {
          width: 70%;
        }
        h1, p {
          color: $white;
        }
        h2 {
          text-transform: initial;
          font-weight: 100;
          @include mq($small) {
            font-size: 30px;
          }
          
        }
        p {
          font-size: 16px;
          line-height: 1.8;
          @include mq($small) {
            font-size: 18px;
            // line-spacing: 1.8;
          }
        }
       

      }
      .disbursement-form {
        box-sizing: border-box;
        width: 100%;
        position: fixed;
        left: 0;
        display: none;
        overflow: auto;
        height: 100vh;
        top: 0;
        z-index: 100;
        @include mq($medium) {
          width: 330px;
          display: block;
          top: initial;
          left: initial;
          height: auto;
          z-index: 3;
        }

        .close {
          position: absolute;
          color: #00447e;
          font-size: 18px;
          right: 10px;
          top: 0;
          cursor: pointer;
          @include mq($small) {
            display: none;
          }
        }
        .row {
          .form-control {
            margin-bottom: 20px;
          }
          .form-group {
            h2 {
              margin-bottom: 20px;
            }
            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;
            // height: 60px;
            // margin-bottom: 20px;
            

            .submit-button {
              background: #e8d766;
              width: 100%;
              box-sizing: border-box;
              height: 40px;
              padding: 12px 10px;
              color: #00447e;
              font-family: Montserrat;
              font-size: .875rem;
              letter-spacing: .1em;
              line-height: 1;
              text-align: center;
              text-transform: uppercase;
              font-style: normal;
              white-space: nowrap;
              cursor: pointer;
              border: none;
              font-weight: 700;
            }
          }
        }
        h2 {
          margin-bottom: 0;
          &.alternate {
            color: #00447e;
          }
        }
        background: $white;
        border: 15px solid #03447E;

        padding: 20px;
        @include mq($small) {
          position: absolute;
          left: 70%;
        }
        label {
          // 
          color: $black;
          font-size: 15px;
          font-weight: 400;
          // 
          @include mq($small) {
            display: block;
            margin-bottom: -5px;
          }
          &.form-check-label {
            display: inline;
          }
        }
        

        input[type=email], 
        input[type=text] {
          background: $white;
          color: #666;
          border: 1px solid #d6d6d6;
          height: 32px;
          width: 98%;
          font-size: 14px;
          font-family: "Fira Sans";
          line-height: 1.2;
          font-weight: 400;
        }
        .input {
          &.form-control {
            border: 1px solid;
           
          }
        }
      }
    }
  }

  .header-secondary {
    
    background: #1960A0;
    @include mq($small) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    .header-secondary-container {
      width: 70%;
      p {
        font-size: 18px;
        line-height: 1.6;
        color: $white;
        font-weight: 300;
        strong {
          color: $yellow;
        }
        b {
          color: $yellow;
        }
      }
    }
  }



.banner {
  .padded {
    .support {
    
    
    }
  }
}

.banner.solutions-for-lawyers-settlement-loans {
  background-image: url(/img/solutions/for-lawyers/settlement-loans/banner.png);
}

.banner.solutions-for-lawyers-preferred-program {
  background-image: url(/img/solutions/for-lawyers/preferred-program/banner.png);
}

.banner.solutions-for-lawyers-expert-access {
  background-image: url(/img/solutions/for-lawyers/expert-access/banner.png);
}

.banner.solutions-for-lawyers-treatment-access {
  background-image: url(/img/solutions/for-lawyers/treatment-access/banner.png);
}

.banner.solutions-for-experts-expert-access {
  background-image: url(/img/solutions/for-experts/expert-access/banner.png);
}

.banner.solutions-for-plaintiffs-settlement-loans {
  background-image: url(/img/solutions/for-plaintiffs/settlement-loans/banner.png);
}

.banner.settlement-loans {
  background-image: url(/img/settlement-loans/banner.png);
}

.banner.about,
.banner.partners,
.padded.testimonials,
.padded.careers,
.padded.contact,
.padded.team-member {
  padding-top: 90px;
  padding-bottom: 90px;
  background-color: #F2F2F2;
  color: #848484;
}

.padded.testimonials {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media(max-width: 1099px) {

  .padded.careers,
  .padded.contact,
  .padded.team-member {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.banner h1 {
  margin-bottom: 35px;
  color: inherit;
  font-size: 3.5rem;
  line-height: 1.4;
  letter-spacing: 0.04em;
}


.banner h1.has-image {
  position: relative;
  // padding-right: 200px;
  margin-right: 50px;
}

.banner h1.has-image img {
  position: absolute;
  top: 0px;
  right: 0px;
  max-width: 180px;
}

@media(max-width: 767px) {
  .banner h1.has-image+h2 {
    padding-right: 200px;
  }
}

@media(max-width: 600px) {
  .banner h1.has-image {
    padding-right: 0px;
    margin-right: 0px;
    text-align: center;
  }

  .banner h1.has-image img {
    display: block;
    position: static;
    margin: 20px auto 0px;
  }

  .banner h1.has-image+h2 {
    padding-right: 0px;
  }
}

.banner.alternate h1 {
  color: #00447C;
  font-family: "Fira Sans";
  font-size: 4.375rem;
  line-height: 1.25;
  font-weight: 500;
  text-transform: none;
}

.banner.grey h1 {
  color: #00487C;
}

.banner.blue h1 {
  color: inherit;
}

.banner.about h1 {
  color: #848484;
}

.banner.homepage{
  h1{
    margin:0;
    margin-top:.25em;
    font-weight:700;
  }
  p.center{
    margin-top:0;
    font-size:1.5em;
    font-family: $header;
  }

}

.banner.about h1 em {
  font-style: normal;
  color: #17446a;
}

.banner h2 {
  color: #E8D766;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.banner.alternate h2 {
  padding-bottom: 0px;
}

.banner.alternate h2:after {
  content: none;
}

.banner.about h3 {
  margin-bottom: 0px;
  color: #444444;
}

.banner.alternate h3 {
  font-family: Oswald;
  color: inherit;
  font-size: 2.25rem;
  letter-spacing: .05em;
  line-height: 1.25;
  font-weight: 300;
}

.banner .button {
  margin-top: 3.125rem;
}

.banner.blue .button {
  margin-top: 2.5rem;
}

.banner .padded {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 170px;
  padding-bottom: 130px;
}

.banner.has-navigation .padded {
  padding-bottom: 100px;
}

.banner.blue .padded {
  padding-bottom: 50px;
}

.banner .banner-item {
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  background-size: cover;
  background-position: center;
}

.banner .banner-item.show {
  max-height: 9999px;
  opacity: 1;
  -webkit-transition: opacity 150ms;
  transition: opacity 150ms;
}

.banner-item.about {
  background-image: url(/img/homepage/banner-about.png);
}

.banner-item.experts {
  background-image: url(/img/homepage/banner-experts.png);
}

.banner-item.lawyers {
  background-image: url(/img/homepage/banner-lawyers.png);
}

.banner-item.plaintiffs {
  background-image: url(/img/homepage/banner-plaintiffs.png);
}

.banner .banner-navigation {
  position: absolute;
  bottom: 10%;
  left: 0px;
  right: 0px;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.banner .banner-navigation a {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20%;
  height: 45px;
  z-index: 5;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: #777777;
  color: #FFFFFF;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-transition: color 150ms, background-color 150ms;
  transition: color 150ms, background-color 150ms;
}

.banner .banner-navigation a:after {
  content: '';
  position: absolute;
  top: -15px;
  left: calc(50% - 11px);
  width: 0px;
  height: 0px;
  border-bottom: 15px solid #E8D766;
  // border-left: 11px solid transparent;
  // border-right: 11px solid transparent;
  opacity: 0;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
}

.banner .banner-navigation a {
  background-color: #A7A9AC;
  margin-right: 15px;
  margin-left: 15px;
}

.banner .banner-navigation a:hover,
.banner .banner-navigation .selected {
  color: #00447E;
  background-color: #E8D766;
}

.banner .banner-navigation a:hover:after,
.banner .banner-navigation .selected:after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: opacity 100ms, -webkit-transform 150ms;
  transition: opacity 100ms, -webkit-transform 150ms;
  transition: transform 150ms, opacity 100ms;
  transition: transform 150ms, opacity 100ms, -webkit-transform 150ms;
}

.banner .banner-navigation .input {
  -webkit-box-shadow: 0px 3px 7px rgba(170, 170, 170, 0.6);
  box-shadow: 0px 3px 7px rgba(170, 170, 170, 0.6);
}

@media(max-width: 1100px) {

  .banner h1,
  .banner.alternate h1 {
    font-size: 4rem;
    line-height: 1.2;
  }

  h2,
  .alternate h2 {
    font-size: 2.5rem;
  }

  h3,
  .alternate h3 {
    font-size: 1.85rem;
  }
}

@media(max-width: 1024px) {
  .banner .padded {
    padding-top: 120px;
    min-height: 0px;
  }

  .banner h1 .nowrap {
    white-space: normal;
    font-size: 2rem;
  }

  .banner .banner-navigation a {
    font-size: 1rem;
  }
}

@media(max-width: 767px) {

  .banner .banner-navigation {
    flex-direction: column;
    position: relative;
    transform: initial;
  }

  .banner .banner-navigation a {
    width: 300px;
    margin: 15px auto;
  }

  .banner,
  .banner.alternate {
    font-size: 1.525rem;
    line-height: 1.5;
  }

  .banner .padded {
    padding-top: 100px;
  }

  .banner h1,
  .banner.alternate h1 {
    font-size: 2.75rem;
  }

  h2,
  .alternate h2 {
    font-size: 2rem;
  }

  .banner h2,
  .banner.alternate h2 {
    font-size: 1.2rem;
  }

  h3,
  .alternate h3 {
    font-size: 1.5rem;
  }
}

@media(min-width: 1025px) and (max-width: 1200px) {
  .banner h1.scalable-font-size {
    font-size: 4vw;
  }
}

@media(min-width: 1201px) {
  .banner h1.scalable-font-size {
    font-size: 3rem;
  }
}

/*******************************************************************
 columns 
********************************************************************/

.columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 0px -15px 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  &.heading {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;

    h1:only-child,
    h2:only-child,
    h3:only-child,
    p:only-child {
      margin-bottom: 0px;
    }
  }

  .column {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: block;
    width: 100%;
    padding: 0px 7px;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    .body-content {
      p+p {
        margin-top: 0;
      }
    }
  }

  &.has-aside {
    padding-right: 33.3333%;
    position: relative;

    .aside {
      position: absolute;
      top: 0px;
      right: 0px;
      width: calc(33.3333% - 20px);
      max-height: 100%;
    }
  }
}

@media (min-width: 768px) {
  .columns .column {

    &.column-half,
    &.column-third,
    &.column-quarter,
    &.column-three-quarter {
      width: 50%;
    }
  }
}

@media (min-width: 1100px) {
  .columns .column {
    &.column-two-third {
      width: 66.6666%;
    }

    &.column-third {
      width: 33.3333%;
    }

    &.column-quarter {
      width: 25%;
    }

    &.column-three-quarter {
      width: 75%;
    }
  }
}

@media (max-width: 1099px) {
  .columns.has-aside {
    padding-right: 0;
    position: static;

    .aside {
      position: static;
      width: 100%;
      margin-top: 30px;
      padding: 0px 15px;
    }
  }
}

.large-text {
  font-size: 1.4375rem;
  line-height: 1.48;
  color: #444444;

  p {
    color: $white;
  }
  &.expert-access{
    p {
      color: $text;
    }
  }

}
.medium-text {
  font-size: 1.125rem;
}

.light {
  font-weight: 300;
}

.columns {
  &.bold .column {
    font-weight: bold;
  }

  .column {
    &.bold {
      font-weight: bold;
    }

    &.emphasize {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 35px 35px 35px 0px;
      color: #00447E;
      font-family: "Fira Sans";
      font-size: 1.75rem;
      line-height: 1.46;
      font-style: italic;
      text-align: left;

      &.column-third {
        max-width: 320px;
      }

      +.column {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
      }
    }
  }

  &.white-text .column,
  .column.white-text {
    color: #FFFFFF;
  }
}

@media (max-width: 1099px) {
  .columns .column.emphasize {
    position: relative;
    width: 100%;
    max-width: 100% !important;
    padding: 30px 15px;
    margin-bottom: 30px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: calc(50% - 30px);
      width: 60px;
      height: 3px;
      background-color: #E8D766;
    }
  }
}

@media (min-width: 1100px) {
  .columns .column.emphasize {
    padding: 35px 35px 35px 0px;
    text-align: right;
    border-right: 3px solid #E8D766;

    +.column {
      padding: 35px 0px 35px 35px;
    }
  }
}

@media (max-width: 767px) {
  .columns .column.emphasize {
    font-size: 1.5rem;
  }

  .banner {

    .banner-item.show {
      height: 100vh;
    }
    @at-root{
      .banner.homepage{
        .banner-item.show {
          height: auto;
        }
      }
    }
  }

}

.solutions-for-lawyers-expert-access-how-it-works {
  .columns {
    margin-top: 60px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .column {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .number {
    width: 120px;
    height: 120px;
    margin-bottom: 35px;
  }

  p {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .dots {
    margin-top: 15px;
    margin-bottom: 30px;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
}

.solutions-for-lawyers-expert-access-our-expert-partners .column {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* module boxes */

.module-box {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: calc(100% - 30px);
  color: #666666;
  background: #F2F2F2;
  padding: 30px 30px 60px;
  margin: 0px 0px 30px 0px;
  font-weight: 300;

  &:after {
    content: '';
    background: url(/img/interfaces/module-corner-white-bg.png) no-repeat center;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    z-index: 10;
  }
}

.module {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: relative;
  height: calc(100% - 30px);
  color: #666666;
  background: #F2F2F2;
  padding: 30px 30px 60px;
  margin: 0px 0px 30px 0px;
  font-weight: 300;

  &:hover {
    p.button {
      transition: .5s all;
    }

    p.button.white {
      color: $yellow;
      border-color: $yellow;
    }

    p.button.lt-gray {
      color: $blue;
      border-color: $blue;
    }
  }
}

.module:after {
  content: '';
  background: url(/img/interfaces/module-corner-white-bg.png) no-repeat center;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  z-index: 10;
}

.module.no-corner {
  padding: 30px;
}

.module.no-corner:after {
  content: none;
}

.grey .module {
  background-color: #FFFFFF;
}

.grey .module:after {
  background: url(/img/interfaces/module-corner-grey-bg.png) no-repeat center;
}

.module.has-image-before {
  padding-left: calc(50% + 45px);
}

.module.has-image-after {
  padding-right: calc(50% + 60px);
}

.module .image {
  display: block;
  width: 100%;
  background-color: #B1B1B1;
  color: #F2F2F2;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  background-size: cover;
}

.module.has-image-before .image {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: calc(50% + 15px);
  margin: 0px;
  -o-object-fit: cover;
  object-fit: cover;
}

.module.has-image-after .image {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: calc(50% + 15px);
  margin: 0px;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

.module.has-background-image {
  background-size: cover;
  color: #FFF;
  background-color: #86888B;
}

.module.blog-module {
  padding-top: calc(65% + 25px);
  background-position: top center;
  background-size: 100% auto;
}

.module.blog-module .image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  padding-bottom: 65%;
}

.module.testimonial-module-container {
  padding: 0;
}

.module .home-testimonial {
  display: block;
  width: 100%;
  background-color: #B1B1B1;
  color: #F2F2F2;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  height: 100%;
}

@media (max-width:767px) {
  .testimonial-container {
    p {
      padding-top: 30px;
    }

    p+p {
      padding-bottom: 30px;
    }
  }
}

@media(min-width:1100px) {
  .module.blog-module {
    padding-top: calc(65% + 30px);
  }
}

.module.has-background-image .category,
.module.has-background-image .title {
  color: inherit;
}

.module.large-text {
  padding: 40px;
  font-size: 1.4375rem;
  line-height: 1.47;
  color: #444444;
}

.aside .module {
  margin: 0px 0px 30px;
}

.aside .module img {
  width: calc(100% + 60px);
  margin: -30px -30px 30px;
}

.module .category {
  display: block;
  margin-bottom: 25px;
  color: #00447E;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.module.large-text .category {
  font-size: 1.125rem;
  color: #444444;
}

.module .title {
  position: relative;
  display: block;
  padding-bottom: 25px;
  margin-bottom: 25px;
  color: #848484;
  font-family: "Fira Sans";
  font-size: 1.875rem;
  line-height: 1.1em;
  font-weight: normal;
}

.module .title:after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 60px;
  background-color: #E8D766;
}

.module .title.no-after {
  padding-bottom: 0px;
  margin-bottom: 15px;
}

.module .title.no-after:after {
  content: none;
}

.module.large-text .title {
  font-size: 2.375rem;
  color: #00447e;
  font-weight: 500;
}

.module .date {
  display: block;
  font-weight: 500;
  font-size: 0.875rem;
  color: #00447E;
}

.module .learn-more {
  font-size: 1rem;
  font-weight: bold;
}

.module .learn-more em {
  font-style: normal;
  color: #00447e;
  font-weight: 400;
}

.module.has-controls {
  padding-bottom: 70px;
}

.module.has-controls .controls {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 0px 0px 25px 30px;
  color: #808285;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8125rem;
  font-weight: bold;
}

.module.has-controls .controls a {
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.module.has-controls .controls .previous,
.module.has-controls .controls .next {
  display: inline-block;
  margin-right: 25px;
  width: 16px;
  height: 30px;
  background: url(img/interfaces/icons/module-controls-previous.svg) no-repeat center / 16px 30px;
  cursor: pointer;
  font-size: 0;
}

.module.has-controls .controls .next {
  background-image: url(img/interfaces/icons/module-controls-next.svg);
}

.module.twitter-feed {
  width: 100%;
}

.module.twitter-feed p {
  width: 100%;
  word-wrap: break-word;
}

.module.twitter-feed #twitter-feed {
  width: 100%;
}

@media(max-width: 1024px) {

  .module,
  .module.large-text {
    padding: 25px 25px 40px;
  }

  .module .category,
  .module.large-text .category {
    margin-bottom: 15px;
  }

  .module .title,
  .module.large-text .title {
    padding-bottom: 15px;
    margin-bottom: 15px;
    font-size: 1.75rem;
  }

  .module.has-controls .controls {
    padding: 0px 0px 20px 20px;
  }
}

@media(max-width: 767px) {
  .module {
    margin: 0px 0px 25px 0px;
  }

  .module.has-image-before,
  .module.has-image-after {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 270px;
  }

  .module.has-image-before .image,
  .module.has-image-after .image {
    top: 0px;
    left: 0px;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 250px;
  }
}


/* video boxes */

.video,
.videos .video {
  display: block;
  color: #00477C;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5;
  cursor: pointer;
  margin-bottom: 45px;
}

.video .video-thumbnail {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  background-color: #D1CFCF;
}

.video .video-thumbnail img {
  width: 100%;
}

.videos .video .video-thumbnail {
  height: 280px;
}

.video .video-thumbnail .play-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  padding-top: 60px;
  background: url(img/interfaces/icons/play.png) no-repeat top center / 43px 50px;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6875rem;
  font-weight: bold;
  letter-spacing: .1em;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  opacity: 0.8;
  -webkit-transition: opacity 150ms;
  transition: opacity 150ms;
}

.video:hover .video-thumbnail .play-video {
  opacity: 1;
}

.video .video-thumbnail .play-video:not(:empty) {
  background-size: 70px auto;
  padding-top: 100px;
}

.video-player-container {
  position: relative;
  width: 100%;
  height: 0px;
  padding-bottom: 56.25%;
}

.video-player-container iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}


/* bridgepoint team */

.the-bridgepoint-team .columns {
  margin-bottom: 0px;
}

.the-bridgepoint-team .team-list {
  margin-top: 55px;
}

.the-bridgepoint-team .team-list .column {
  overflow: hidden;
  height: 485px;
  max-width: 310px;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-transition: opacity 100ms 100ms, width 100ms, height 0ms 100ms, padding 100ms;
  transition: opacity 100ms 100ms, width 100ms, height 0ms 100ms, padding 100ms;
}

.the-bridgepoint-team .team-list .filtered {
  opacity: 0;
  width: 0px;
  height: 0px;
  padding: 0px;
  -webkit-transition: opacity 100ms, width 100ms 100ms, height 100ms 100ms, padding 100ms 100ms;
  transition: opacity 100ms, width 100ms 100ms, height 100ms 100ms, padding 100ms 100ms;
}

.the-bridgepoint-team .team-list .module {
  width: 100%;
  padding: 25px;
  color: #00447E;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
}

.the-bridgepoint-team .team-list img {
  margin-left: -25px;
  margin-top: -25px;
  margin-bottom: 25px;
  width: calc(100% + 50px);
  background-color: #ABABAB;
}

.the-bridgepoint-team .team-list .name {
  display: block;
  margin-bottom: 10px;
  color: #17446A;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.01em;
}

.the-bridgepoint-team .team-list .job-title {
  display: block;
  margin-bottom: 25px;
  color: #444444;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.the-bridgepoint-team .team-list .email {
  display: block;
  margin-bottom: 10px;
}

.padded.team-member {
  color: #333;
}

.team-member h1 {
  margin-top: 3.125rem;
  margin-bottom: 2.1875rem;
}

.team-member h2 {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: .1em;
  color: #444444;
}

.team-member p:first-child {
  font-weight: 300;
  font-size: 150%;
}

.team-member-photo-and-info {
  width: 100%;
}

.team-member-photo {
  display: block;
  width: 100%;
  height: auto;
}

.team-member-contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.team-member-contact-info {
  font-size: 1.15rem;
  line-height: 1;
  margin-bottom: 2rem;
}

.team-member-contact-info strong {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: 50%;
  padding: 30px 35px;
  background-color: #87888c;
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: 600;
}

.team-member-contact-info .contact-info {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: 50%;
  padding: 30px 35px;
  background: #FFF;
  color: #00447E;
}

.team-member-contact-info .email {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}


/* about us - bottom section */

.about-bottom-list .title {
  color: #00487C;
}

.about-bottom-list p {
  font-weight: 500;
  font-size: 1.125rem;
}

.about-bottom-list .button {
  margin-top: 25px;
  margin-bottom: 0px;
}


/* partners page */

.partners .columns .column.emphasize {
  color: #444444;
  font-size: 1.5rem;
  line-height: 1.5;
}

.partners-list {
  width: 100%;
  color: #17446A;
  font-size: 1.875rem;
  line-height: 1.7;
  font-weight: 400;
}

@media(max-width: 767px) {
  .partners-list li {
    margin-bottom: 10px;
    font-size: 1.4rem;
  }
}


/* careers page */

.padded.careers {
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 400;
  color: #444444;
}

.careers h3,
#BambooHR .BambooHR-ATS-board h2 {
  font-family: "Fira Sans", Arial, sans-serif;
  font-size: 1.875rem;
  font-weight: bold;
  color: #00487c;
  line-height: 1;
  margin-bottom: 20px;
  padding-bottom: 0px !important;
  border-bottom: none !important;
}

.BambooHR-ATS-board ul {
  padding: 0px !important;
  border: none !important;
}

.careers li {
  font-size: 1.125rem;
}

.careers li+li {
  margin-top: 15px;
}

.careers h6,
#BambooHR .BambooHR-ATS-Department-Header {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 10px;
}

.careers strong,
#BambooHR .BambooHR-ATS-board a {
  color: #00487c;
  font: inherit;
}

#BambooHR .BambooHR-ATS-board .BambooHR-ATS-Location {
  padding-top: none;
  float: none;
  color: inherit;
  color: #444;
}

#BambooHR .BambooHR-ATS-board .BambooHR-ATS-Location:before {
  content: ' - ';
}

#BambooHR #BambooHR-Footer {
  text-align: left;
  vertical-align: bottom;
}

@media(min-width: 900px) {
  .careers .careers-description {
    padding-right: 35px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .careers .open-positions {
    border-left: 3px solid #E8D766;
    padding-left: 35px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .careers .open-positions div {
    -ms-flex-item-align: center;
    align-self: center;
  }
}

@media(max-width: 767px) {
  .careers .open-positions {
    padding-top: 30px;
  }
}


/* testimonials page */

.testimonial-section {
  box-sizing: border-box;
  max-width: 1200px;

  // clearfix: both;
  .testimonial-grid {
    height: auto;
    float: left;
    width: 100%;

    @include mq($medium) {
      width: 375px;
    }

    @include mq($large) {
      width: 450px;
    }

    @include mq($desktop) {
      width: 585px;
    }

    //   -webkit-box-sizing: border-box;
    //   -moz-box-sizing: border-box;
    //   box-sizing: border-box;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
}

.testimonials-controls {
  // padding-bottom: 50px;
  // background: #f2f2f2;
  clear: both;
}

.testimonials-index-list-bottom,
.testimonials-index-list {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.module.testimonial {
  padding: 40px;
}

.module.testimonial:after {
  content: none !important;
}

.module.testimonial p {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: normal;
  color: #444444;
  font-style: italic;
  margin-bottom: 25px;
}

.testimonial-controls {
  clear: both;
}

.testimonials-controls,
.blogs-controls {
  text-align: center;
}

.showing-all .testimonials-controls,
.showing-all .blogs-controls {
  display: none;
}

@media(max-width: 767px) {
  .module.testimonial {
    padding: 25px;
  }

  .module.testimonial p {
    margin-bottom: 15px;
  }
}

.has-video {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  // position: relative !important;
  width: 50%;
  padding: 0px 0px 26.25% !important;
  position: relative;
}

.has-video iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: none;
}

/* Override for video testimonial on settlement loans page */
#testimonials .has-video {
  width: 100%;
  padding: 0px 0px 56% !important;
}


/* feature listing/table */

.feature-list {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 40px 40px 10px;
  margin: 0px 0px 65px 0px;
  border: 1px solid #00447C;
  list-style: none;
}

.feature-list li {
  padding: 17px 0px;
  border-top: 1px solid #00447C;
}

.feature-list .heading {
  margin: -40px -40px 20px;
  padding: 40px;
  background-color: #00447C;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 1.375rem;
  letter-spacing: .1em;
  line-height: 1;
}

.feature-list .heading,
.feature-list .heading+li {
  border-top: none;
}

@media(max-width: 767px) {
  .feature-list {
    margin: 0px 0px 30px 0px;
    padding: 25px 25px 10px;
  }

  .feature-list .heading {
    margin: -25px -25px 10px;
    padding: 25px;
  }
}


/* testimonials */

.testimonials {
  padding-top: 45px;
  padding-bottom: 45px;
  color: #FEFEFE;
  font-size: 1.25rem;
  line-height: 1.65;
  font-style: italic;
  letter-spacing: .01em;
  text-align: center;
  background-color: #B1B1B1;
  background-position: center;
  background-size: cover;
}

.testimonials .testimonials-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #FEFEFE;
  padding: 80px 90px;
  position: relative;
  overflow: hidden;
  background: url(/img/interfaces/icons/testimonials-quote.png) no-repeat top center;
}

.testimonials .testimonial {
  opacity: 0;
  height: 0px;
  overflow: hidden;
  -webkit-transition: opacity 250ms;
  transition: opacity 250ms;
}

.testimonials .testimonial.show {
  opacity: 1;
  height: auto;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: opacity 250ms, height 0ms, -webkit-transform 250ms;
  transition: opacity 250ms, height 0ms, -webkit-transform 250ms;
  transition: opacity 250ms, transform 250ms, height 0ms;
  transition: opacity 250ms, transform 250ms, height 0ms, -webkit-transform 250ms;
}

.testimonials .testimonial.in-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  opacity: 1;
}

.testimonials .testimonial.out-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  opacity: 0;
}

.testimonials .testimonial.in-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 1;
}

.testimonials .testimonial.out-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
}

.testimonials .testimonial .credit {
  font-size: 1rem;
  line-height: 1;
  font-family: 'Montserrat', sans-serif;
  vertical-align: middle;
  font-style: normal;
  text-transform: uppercase;
  font-weight: normal;
}

.testimonials .testimonial-controls {
  padding-top: 35px;
}

.testimonials .testimonial-controls .next,
.testimonials .testimonial-controls .previous {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 50px;
  left: 0;
  cursor: pointer;
  font-size: 0px;
  background: url(/img/interfaces/icons/testimonials-next.svg) no-repeat center / 14px auto;
}

.testimonials .testimonial-controls .next {
  left: auto;
  right: 0;
  background-image: url(/img/interfaces/icons/testimonials-previous.svg);
}

.testimonials .testimonial-controls .button {
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.testimonials.solutions-for-lawyers-settlement-loans {
  background-image: url(/img/solutions/for-lawyers/settlement-loans/testimonials.png);
}

.testimonials.solutions-for-lawyers-preferred-program {
  background-image: url(/img/solutions/for-lawyers/preferred-program/testimonials.png);
}

.testimonials.solutions-for-lawyers-expert-access {
  background-image: url(/img/solutions/for-lawyers/expert-access/testimonials.png);
}

.testimonials.solutions-for-lawyers-treatment-access {
  background-image: url(/img/solutions/for-lawyers/treatment-access/testimonials.png);
}

.testimonials.solutions-for-experts-expert-access {
  background-image: url(/img/solutions/for-experts/expert-access/testimonials.png);
}

.alternate.testimonials {
  text-align: left;
  color: #444444;
  font-size: 1rem;
  line-height: 1.5;
  font-style: normal;
  letter-spacing: 0;
}

.alternate.testimonials h2 {
  padding-bottom: 0px;
}

.alternate.testimonials h2:after {
  content: none;
}

.alternate.testimonials .columns {
  margin-bottom: 0px;
}

.alternate.testimonials .columns+.columns {
  margin-top: 50px;
}

.alternate.testimonials .column {
  display: block;
}

.alternate.testimonials .column-quarter {
  padding-top: 60px;
  padding-bottom: 30px;
  background: url(/img/interfaces/icons/testimonials-quote-yellow.png) no-repeat 10px top;
}

.alternate.testimonials strong {
  display: block;
  margin-top: 10px;
  color: #00477C;
}

@media(max-width: 850px) {
  .testimonials .testimonials-list {
    padding: 60px 50px;
  }
}

@media(max-width: 500px) {
  .testimonials .testimonials-list {
    padding: 60px 40px;
  }
}

@media(min-width: 768px) {
  .alternate.testimonials {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media(min-width:1200px) {
  .alternate.testimonials {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}


/* newsletter-callout */

.newsletter-callout {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background: #F2F2F2;
  overflow: hidden;
  display:flex;
  flex-direction:column;
  padding-bottom:0;

  /* Media (1100px)
  -------------------------------------------------- */
  @media screen and (min-width: 1100px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: #FFF;
      text-align: left;
      align-items: center;
      padding-bottom: 30px;
  
  } // 1100px Ends ---->
  .callout {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    min-height: 100px;
    padding: 20px 30px;
    background-color:$grey;
    color: #666666;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.125rem;
    line-height: 1;
    font-weight: 300;
    /* Media (1100px)
    -------------------------------------------------- */
    @media screen and (min-width: 1100px) {
        background-color:transparent;
        padding: 0;
        display:flex;
        flex-direction:column;
        justify-content: center;
    } // 1100px Ends ---->
    h4 {
      margin-bottom: 15px;
      color: #00487C;
      font-family: Oswald;
      font-size: 2rem;
      line-height: 1;
      text-transform: uppercase;
    }
    p{
      /* Media (1100px)
      -------------------------------------------------- */
      @media screen and (min-width: 1100px) {
          margin-top:0;
      } // 1100px Ends ---->
    }
  }
  
  .readers-choice-awards{
    display:flex;
    justify-content: center;
    align-items:center;
    order:3;
    background-color:$white;
    margin-right:-30px;
    margin-left:-30px;
    padding:2em 0;

    /* Media (1100px)
    -------------------------------------------------- */
    @media screen and (min-width: 1100px) {
        order:2;
        padding:0;
        margin:0;
    } // 1100px Ends ---->
    .readers-choice-award{
      width:400px;
      
      padding:0 .25em;
      /* Media (1100px)
      -------------------------------------------------- */
      @media screen and (min-width: 1100px) {
          // width: 65px;
      } // 1100px Ends ---->

      /* Media (1300px)
      -------------------------------------------------- */
      @media screen and (min-width: 1300px) {
          // width:85px;
      } // 1300px Ends ---->
      img{
        width:100%;
      }
    }
  }
  .call-out-button{
    order:2;
    margin-bottom:1em;
    background-color:$grey;
   
  
    /* Media (1100px)
    -------------------------------------------------- */
    @media screen and (min-width: 1100px) {

        order:3;
        max-width: 165px;
        flex-grow: 1;
        min-width: 0px;
        .button {
          border-radius: 0;
          height: 55px;
          padding: 18px 25px;
          width:100%;
          min-width:0;
          
          
        }
    } // 1100px Ends ---->


  }
}



// .newsletter-callout .readers-choice-logos {
//   background: #FFF;
//   margin: 30px -20px -30px;
//   padding: 30px 15px;
// }

// .newsletter-callout .readers-choice-logos img {
//   width: 250px;

//   @include mq($small) {
//     width: 200px;
//   }

// }

// .newsletter-callout .readers-choice-logos img+img {
//   margin-left: 15px;
// }

// @media(min-width: 768px) {
//   .newsletter-callout .readers-choice-logos {
//     margin: 30px -100px -30px;
//   }
// }





.footer-modules-invert-top-margin {
  margin-top: -60px;
}


/* footer */

footer.padded {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: rgba(128, 130, 133, .95);
  color: #D1D3D4;
  font-family: "Fira Sans";
  font-size: 0.875rem;
  line-height: 1;
}

footer .footer-logo {
  width: 99px;
  height: 41px;
  margin-bottom: 20px;
}

footer p+p {
  margin-top: 15px;
}

footer p.copyright {
  font-family: Oswald;
  font-size: 0.75rem;
  text-transform: uppercase;
}

footer p.copyright a {
  text-decoration: underline;
}

footer .scroll-to-top {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: 30px;
  border: 1px solid #D1D2D4;
  cursor: pointer;
  background: url(/img/interfaces/scroll-to-top.svg) no-repeat center / 15px 16px;
}

@media(min-width: 1024px) {
  footer.padded {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  footer .footer-logo {
    margin-bottom: 0px;
    margin-right: 30px;
  }

  footer p+p {
    margin-top: 8px;
  }

  footer .scroll-to-top {
    margin-top: 0px;
    margin-left: auto;
  }
}

@media(max-width: 767px) {
  footer.padded {
    font-size: 11px;
    line-height: 1.45;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  footer p.copyright {
    font-size: inherit;
  }

  footer p+p {
    margin-top: 8px;
  }

  footer .scroll-to-top {
    margin-top: 20px;
  }
}


/* overrides */

.no-top-padding {
  padding-top: 0px !important;
}

.no-bottom-padding {
  padding-bottom: 0px !important;
}

.no-top-margin {
  margin-top: 0px !important;
}

.no-bottom-margin {
  margin-bottom: 0px !important;
}

.invert-top-margin {
  margin-top: -30px !important;
}

.invert-bottom-margin {
  margin-bottom: -30px !important;
}

.invert-top-margin {
  margin-top: -30px !important;
}

@media(max-width: 1100px) {
  .no-top-padding-tablet {
    padding-top: 0px !important;
  }

  .no-bottom-padding-tablet {
    padding-bottom: 0px !important;
  }

  .no-top-margin-tablet {
    margin-top: 0px !important;
  }

  .no-bottom-margin-tablet {
    margin-bottom: 0px !important;
  }
}


/* form messages */

.message {
  margin: 1rem 0;
  font-family: Oswald;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.message.error {
  color: #ce1e1e;
}

.message.success {
  color: #19af4f;
}

.error-message {
  margin-top: .5rem;
  font-size: .9rem;
  color: #ce1e1e;
}

.left-padding {
  padding-left: 50px;
}

@media(max-width: 767px) {
  .left-padding {
    padding-left: 0;
  }
}

#Layer_1 {
  animation-name: ckw;
  animation-duration: 100s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  display: inline-block;
}

@keyframes ckw {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.solutions-for-plaintiffs-settlement-loans {
  .award-container {
    display: block;
    margin-top: 30px;
    text-align: center;

    @include mq($medium) {
      margin-top: 0;
      display: inline;
      text-align: left;
    }

    .award-icon {
      width: 200px;
      margin-left: 15px;
      margin-right: 15px;

      @include mq($small) {
        width: 275px;
        margin-right: 25px;
        margin-left: 25px;
      }
    }

    +p {
      display: block;
    }
  }

}

// Override to show hieght of svg in ie11
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .partners-image {
    #Layer_1 {
      height: 568px;
    }
  }
}

.testimonial-slide {
  box-sizing: border-box;
  height: 450px;
}