$small: 769px;
$medium: 1024px;
$large: 1200px;
$desktop: 1400px;

$black: #000;
$text: #333;
$white: #fff;
$yellow: #e8d766;
$blue: #00447e;
$lightGray: #a7a9ac;
$warning: #dc3545;
$grey: #f2f2f2;


$header: Oswald, sans-serif;
$body: "Fira Sans", Arial, sans-serif;