



/* =============================================================================
    hero additions 
============================================================================= */


/* Banner Changes
=================================================== */
.homepage{
    .hero-content{
        justify-content: center;
    }
}

/* Hero Awards
=================================================== */

.hero-awards{
    display:flex;
    justify-content: center;
    align-items: center;
    padding-top:2em;
    img{
        width:500px;
        height:auto;
    }
}



/* Hero Cta Cards
=================================================== */
.hero-cta-cards.padded{
    // background-color:$white;
    position: relative;
    display:flex;
    justify-content: space-between;
    color:$blue;
    padding-top:0 !important;
    padding-bottom:0 !important;
    /* Media (768px)
    -------------------------------------------------- */
    @media screen and (max-width: 768px) {
        flex-direction:column;
    } //  768px Ends ---->
    .hero-cta-card{
        transform: translateY(-25%);
        background-color:$grey;
        width:calc(50% - 1em);
        text-align:center;
        /* Media (768px)
        -------------------------------------------------- */
        @media screen and (max-width: 768px) {
            width:100%;
            &:first-child{
                margin-bottom:3em;
            }
        } //  768px Ends ---->
    }
    .hero-cta-card-title{
        background-color:$yellow;
        color:$blue;
        font-family:$header;
        padding:.25em 2em;
        text-align:center;
        font-size:1.875rem;
        font-weight:500;
    }
    .hero-cta-card-content{
        padding:2em 3em;
    }
    .hero-cta-card-content-main{
        color:$blue;
        text-transform: uppercase;
    }
    .hero-cta-card-content-small{
        margin-bottom:2em;
    }
    .buttons{
        .button:first-child{
            margin-bottom:1em;
        }
    }
}



/* Homepage Testimonial
=================================================== */

.home-testimonial--full.padded{
    background-size:cover;
    background-repeat: no-repeat;
    border-bottom:.5em solid $yellow;
    padding-top:5em;
    padding-bottom:3em;
    blockquote{
        font-family:$header;
        font-size:2.25em;
        color:$white;
        text-align: center;
        margin-bottom:1em;
    }
    p { 
        text-align:center;
        margin-bottom:2em;
        font-size:1.15em;
        span {
            font-family:$header;
            color:white;
        }

    }
}