// Default media query for mobile first
@mixin mq($size, $limiter: "min-width", $max-size: "") {
  @if ($limiter == "between") {
    @media only screen and (min-width: $size) and (max-width: $max-size) {
      @content;
    }
  }
  @else {
    @media only screen and ($limiter: $size) {
      @content;
    }
  }
}

@mixin h-padding($side) {
  padding-right: $side;
  padding-left: $side;
}

@mixin v-padding($side) {
  padding-top: $side;
  padding-bottom: $side;
}

@mixin h-margin($side) {
  margin-left: $side;
  margin-right: $side;
}

@mixin v-margin($side) {
  margin-top: $side;
  margin-bottom: $side;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

// @mixin rounded($radius: 0.5em) {
//   -webkit-border-radius: $radius;
//   -moz-border-radius: $radius;
//   border-radius: $radius;
//   background-clip: padding-box;  /* stops bg color from leaking outside the border: */
// }

@mixin rounded($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// Single side border-radius

@mixin rounded-top($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}
@mixin rounded-right($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}
@mixin rounded-bottom($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin rounded-left($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

@mixin transition($time, $element, $transition) {
  transition: $time $element $transition;
}