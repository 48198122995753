.content {
  ol {
    list-style: decimal;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  p {
    em {
      display: block;
      margin-left: 20px;
    }
  }
}