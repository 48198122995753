/* footer */

footer {
  &.padded {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: rgba(128, 130, 133, 0.95);
    color: #D1D3D4;
    font-family: "Fira Sans";
    font-size: 0.875rem;
    line-height: 1;
  }
  .footer-logo {
    width: 99px;
    height: 41px;
    margin-bottom: 20px;
  }
  p {
    + p {
      margin-top: 15px;
    }
    &.copyright {
      font-family: Oswald;
      font-size: 0.75rem;
      text-transform: uppercase;
      a {
        text-decoration: underline;
      }
    }
  }
  .scroll-to-top {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-top: 30px;
    border: 1px solid #D1D2D4;
    cursor: pointer;
    background: url(/img/interfaces/scroll-to-top.svg) no-repeat (center / 15px) 16px;
  }
}

@media (min-width: 1024px) {
  footer {
    &.padded {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .footer-logo {
      margin-bottom: 0px;
      margin-right: 30px;
    }
    p + p {
      margin-top: 8px;
    }
    .scroll-to-top {
      margin-top: 0px;
      margin-left: auto;
    }
  }
}

@media (max-width: 767px) {
  footer {
    &.padded {
      font-size: 11px;
      line-height: 1.45;
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }
    p {
      &.copyright {
        font-size: inherit;
      }
      + p {
        margin-top: 8px;
      }
    }
    .scroll-to-top {
      margin-top: 20px;
    }
  }
}

/*********************************************************************************
Apply page style
*********************************************************************************/

footer.apply-footer {
  background: #074d84;
  padding: 30px 0;
  color: #fff;
  .wrapper {
    max-width: 940px;
    margin: 0 auto;
    position: relative;
    p {
      font: 14px/16px droid sans;
      margin-bottom: 0;
    }
  }
  .link {
    color: #fff;
  }
}