.hidden {
  display: none;
}

.apply-form-split {
  display: flex;
  position: relative;
  .button {
    color: $white;
  }
  
}
.apply-wrapper {
  max-width: 940px;
  margin: 0 auto;
  position: relative;
  .hr {
    margin: 30px 0;
  }
  .return {
    background: url(../../img/apply/bg-return.png) no-repeat left center;
    padding-left: 30px;
    text-decoration: none;
    color: #fff;
    position: absolute;
    top: -110px;
    left: 0;
    z-index: 9999;
    text-transform: uppercase;
    font-family: "Fira Sans", Arial,sans-serif;
    font-size: 12px;
    line-height: 16px;
    
  }
  p {
    font-family: "Fira Sans", Arial,sans-serif;
    font-size: 16px;
    line-height: 28px;
    
    span {
      color: #eb0029;
    }
  }

  h1 {
    font-family: "Fira Sans", san-serif;
    color: #074d84;
    font-size: 30px;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 35px;
    
  }

  h2 {
    color: #074d84;
    font-family: 'Fjalla One', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 30px;
  }
  strong {
    font-weight: 600;
  }

  .is-invalid {
    border-color: $warning;

    background: #ffd7da url(../../img/apply/icon-select.png) no-repeat 98% 50%!important;
  }

}

.must-have-lawyer {
  display: none;
}
.freeform-label {
  font-size: 15px;
  line-height: 28px;
  display: block;
  // margin-bottom: 5px;
  font-weight: 100 !important;
}
input[type="email"],
input[type="text"] {
  background: #fff;
  border: 1px solid #d6d6d6;
  height: 40px;
  width: 98%;

  color: #666;
  font-family: Arial, san-sefif;
  font-size: 14px;
  line-height: 16px;
}
input[type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance:    checkbox;
  appearance:         checkbox;
}
input[type="radio"] {
  -webkit-appearance: radio;
  -moz-appearance:    radio;
  appearance:         radio;
}
.freeform-input-only-label {
  max-width: 900px;
}

/***************************************
Small apply form window styling
****************************************/

.apply-form {
  display: none;
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  top:0;
  left: 0;
  z-index: 100;
  border: 15px solid #00447e;
  background: #fff;
  padding: 20px;
  flex-direction: row;
  overflow: auto;
  height: 100vh;
  
  @include mq($small) {
    position: relative;
    display: block;;
    width: 40%;
    z-index: initial;
    height: initial;
  }
  .close {
    position: absolute;
    color: $blue;
    right: 10px;
    top: 0;
    cursor: pointer;
    @include mq($small) {
      display: none;
    }
  }
  .alert {
    font-size: 13px;
    color: $warning;
  }
  .is-invalid {
    border-color: $warning;
  }
  .errors {
    li {
      font-size: 13px !important;
      color: $warning !important;
    }
  }
  .row {
    // display: block !important;
    .form-group {
      margin-bottom: 20px;
    }
    
      label {
        font-size: 15px;
    
    }
  }
  p {
    &.small {
      line-height: 1.6 !important;
      font-size: 13px !important;;
    }
  }
  h2 {
    margin-bottom: 0;
    &.alternate {
      color: $blue;
    }

  }
  form {
    .freeform-row {
      &:last-child {
        display: block !important;
      }
    }
  }
  label {
    color: black;
  }
  button {
    background: #e8d766;
    color: #00447e;
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    padding: 12px 30px;
    color: #00447e;
    font-family: Montserrat;
    font-size: .875rem;
    letter-spacing: .1em;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    font-weight: 700;
    &:hover {
      color: #fff;
      background-color: $blue;
      transition: .5s all;
    }

  }
  
  select {
    padding: 2px 8px;
    border: none;
    box-shadow: none;
    background: #f0f0f0;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    color: #555;
    font-size: 14px;
    line-height: 18px;
    height: 40px;

    border: 1px solid #ccc;
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
    background: #cccccc url(../../img/apply/icon-select.png) no-repeat 98% 50% !important;
    option {
      color: black;
    }
  }
  .freeform-label {
    // display: none !important;
  }
  .freeform-input-only-label {
    display: block !important;
  }
  p {    
    margin-top: 0 !important;
    
    font-size: 15px !important;
    line-height: 28px !important;
    color: black !important;
    text-align: center;
  }
}

.apply-wrapper {
  select {
    padding: 2px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background: #f0f0f0;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    color: #555;
    
    font-size: 14px;
    line-height: 18px;

    height: 40px;
    border: 1px solid #ccc;
    border-radius: 2px;
    overflow: hidden;
    background: #cccccc url(../../img/apply/icon-select.png) no-repeat 98% 50% !important;
    &#form-input-accident {
      max-width: 280px;
      margin-right:0;
    }
    &#form-input-referral {
      
      width: 300px;
    }
  }
  .freeform-input-only-label {
    background: #f0f0f0;
    border: 1px solid #d6d6d6;
    padding: 20px;
    margin-top: 25px;
    position: relative;
  }
  .submit-button {
    background: #074d84 url(../../img/apply/bg-submit.png) no-repeat left top;
    height: 40px;
    cursor: pointer;
    border: none;
    color: #fff;
    padding: 0 10px 0 51px;
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    text-transform: uppercase;
    margin-bottom: 30px;
    &:hover {
      background: #555 url(../../img/apply/bg-submit.png) no-repeat left bottom;
    }
  }
  .must-have-lawyer {
    p {
      font-size: 18px;
      color: $blue;
    }
  }
}
.full-apply-form {
  .alert-danger {
    color: #dc3545;
    margin-bottom: 20px;
  }
  .row {
    .form-group {
      margin-bottom: 20px;
    }
  }
}


/****************************************************
Settlement Loans page
****************************************************/

.mobile-apply {
  z-index: 1000;
  display: block;
  position: fixed;
  background: #e8d766;
  color: #00447e;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  padding: 15px 30px;
  color: #00447e;
  font-family: Montserrat;
  font-size: .875rem;
  letter-spacing: .1em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  font-style: normal;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  font-weight: 700;
  bottom: 0;
  // custom media query for 767px portrait
  @include mq(769px) {
    display: none;
  }
}


.mobile-apply-button {
  @include mq($medium) {
    display: none
  }
}
.desktop-apply-button {
  display: none;
  @include mq($medium) {
    display: inline-block;
  }
}



/****************************************************
Mobile
*****************************************************/


@media(max-width: 1024px) {
  .apply-form-split {
    flex-direction: column;
  }
  .apply-form {

  }
}

@media (max-width: 767px) {
  .apply-head {
    height: 200px;
  }
  .apply-form {

  }
  .apply-wrapper {
    padding-left: 30px;
    padding-right: 30px;
    .return {
      left: 30px;
    }
  }

}


.apply-mobile {
 position: fixed;
 bottom: 0;
 z-index: 100;
}

.solutions-for-plaintiffs-settlement-loans {
  position: relative;
}

.freeform-form-has-errors {
  font-size: 15px;
}

.freeform-errors {
  font-size: 14px;
}

/***************************************************
Home Page Apply - Apply form overlay
****************************************************/
.home {
  .apply-form-overlay {
    height: 100vh;
    background-color: rgba($black, .8);
    &.hide {
      display: none;
    }
    position: fixed;
    z-index: 1000;
    width: 100%;
  
    .apply-form-content {
      box-sizing: border-box;
      position: relative;
      height: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      .apply-form {
        display: block;
        margin: 0 auto;
        left: 0;
        right: 0;
        .close-home {
          position: absolute;
          color: #00447e;
          right: 10px;
          top: 0;
          cursor: pointer;
          font-size:22px;
        }
      }
      .apply-form-home {
        height: 100%;
        overflow: auto;
        h2 {
          font-family: "Montserrat",sans-serif;
        }
      }
    }
    

  }
}