.padded.contact {
  color: #333333;
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 400;
}

.contact {
  fieldset {
    margin-top: 40px;
    li + li {
      margin-top: 20px;
    }
    .disclaimer {
      color: #444444;
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.5;
    }
    .button {
      margin-top: 20px;
    }
  }
  .contact-information {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    padding: 30px;
    &:after {
      content: none;
    }
  }
  .message.success {
    display: none;
  }
}