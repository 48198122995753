.apply-header {
  text-transform: uppercase;
  display: none;
  width: 100%;
  background: $yellow;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 700;
  color: $blue;
  height: 40px;
  p {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  &:hover {
    background: #fff;
    color:$blue;
    transition: .5s all;
  }
}
.apply-mobile {
  display: table;
}

@media (min-width: 768px) { // on desktop size
  .apply-mobile {
    display: none;
  }
  .apply-desktop {
    display: table;
  }
}

header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-height: 60px;
  min-height: 100px;
  @include mq($medium) {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    position: static;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  &.padded {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  &.blue-bg {
    background-color: #17446A;
  }
  &.floating {
    position: absolute;
    // top: 40px; Removed becuase apply button was at bottom
    left: 0px;
    right: 0px;
    z-index: 100;
  }
  .logo {
    display: inline-block;
    padding: 15px 0px;
    color: #FFF;

    img {
      display: block;
      height: 38px;
      width: 203px;
    }
    @include mq($medium) {
      border-right: 1px solid #FFFFFF;
      padding: 15px 30px 15px 0px;
      img {
        height: 40px;
        width: 220px;
      }
    }
  }

  .logo {
   
    img {
     
    }
  }


  .toggle-nav {
    display: inline-block;
    width: 32px;
    height: 32px;
    // margin-left: 32px;
    cursor: pointer;
    background: url(/img/interfaces/icons/menu.svg) no-repeat (center / 32px) 32px;
  }

  .telephone {
    display: inline-block;
    width: 32px;
    height: 32px;
    // margin-left: 32px;
    cursor: pointer;
    background: url(/img/interfaces/icons/call-answer-gray.svg) no-repeat (center / 32px) 32px;
    position: absolute;
    right: 55px;
    bottom: 30px;
  }
  nav {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;
    font-family: Montserrat;
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: right;
    @include mq($medium) {
      position: static;
      max-height: 99999px;
    }

  }
  &.blue-bg {
    color: rgba(255, 255, 255, 0.9);
  }
}

@media (max-width:767px) {
  header {
    &.floating {
      top: 0;
    }
  }
}

.banner.has-navigation.homepage {
  // height:100%;
  // background-color:$white;
  .padded {
    padding-bottom:150px;
    min-height:600px;
    height:calc(100vh - 289px);
    /* Media (768px)
    -------------------------------------------------- */
    @media screen and (max-width: 768px) {
      padding-bottom:100px;
      height:calc(100vh - 400px);
    } //  768px Ends ---->
  }
}

@media (min-width: 1200px) {
  header.padded, 
  .hero-cta-cards.padded {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1400px) {
  header.padded, 
  .hero-cta-cards.padded {
    padding-left: calc((100% - 1320px) / 2);
    padding-right: calc((100% - 1320px) / 2);
  }
}

@media (min-width: 1025px) {
  header {
    // Moved to line 52
    // -webkit-box-align: end;
    // -ms-flex-align: end;
    // align-items: flex-end;
    // position: static;
    // -ms-flex-wrap: nowrap;
    // flex-wrap: nowrap;
    nav {
      //moved to line 136
      // position: static;
      // max-height: 99999px;
    }
    
    // moved to line 74
    // .logo {
    //   border-right: 1px solid #FFFFFF;
    //   padding: 15px 30px 15px 0px;
    //   img {
    //     height: 40px;
    //     width: 220px;
    //   }
    // }
    // moved to line 74
    .toggle-nav, .telephone {
      display: none;
    }
    &.floating {
      .logo, nav {
        border-bottom: 1px solid #FFFFFF;
        color: rgba(254, 254, 254, 0.9);
      }
    }
  }

  nav {
    .primary {
      margin-right: -15px;
    }
    li {
      &.logo-container {
        display: none;
        @include mq($medium) {
          display: inline-block;
        }
        .bpf-image {
          display: none;
          @include mq($medium) {
            display: inline;
            width: 130px;
          }
          @include mq($large) {
            width: 150px;
          }
        }
      }
    }
  }
}

@media (min-width: 1165px) {
  header {
    min-height: 150px;
    .logo {
      padding: 15px 50px 30px 60px;
      img {
        height: 55px;
        width: 280px;
      }
    }
    &.floating nav {
      padding-right: 60px;
    }
  }
}

@media (min-width: 1300px) {
  header .logo {
    padding: 15px 60px 30px;
    img {
      height: 67px;
      width: 360px;
    }
  }
}

nav {
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  .primary a {
    display: block;
    padding: 15px;
    color: inherit;
  }
  .secondary {
    display: inline-block;
    color: #DEDEDE;
    font-size: 0.75rem;
  }
  &.floating .secondary {
    color: rgba(222, 222, 222, 1);
  }
  .secondary li {
    display: inline-block;
    vertical-align: middle;
    + li {
      margin-left: 8px;
    }
  }
  li .dropdown {
    max-height: 0px;
    opacity: 0;
    text-align: left;
    overflow: hidden;
    background-color: #777777;
  }
}

@media (max-width: 1024px) {
  header {
    -webkit-transition: background-color 150ms 150ms;
    transition: background-color 150ms 150ms;
    &.show-nav {
      background-color: #003C70;
      -webkit-transition: background-color 150ms;
      transition: background-color 150ms;
    }
    nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0px;
      width: 100%;
      z-index: 10000;
      background-color: #00447E;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      font-size: 1rem;
      -webkit-transition: max-height 150ms 150ms, opacity 150ms;
      transition: max-height 150ms 150ms, opacity 150ms;
    }
    &.show-nav nav {
      max-height: 1000px;
      opacity: 1;
      -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
      -webkit-transition: max-height 150ms, opacity 150ms 150ms;
      transition: max-height 150ms, opacity 150ms 150ms;
    }
  }
  nav {
    .primary li, .secondary .has-dropdown {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .primary {
      li {
        &.logo-container {
          display: none;
        }
      }
    }
    .primary a {
      padding: 15px 15px 15px 25px;
    }
    .secondary .has-dropdown {
      a {
        padding: 15px 15px 15px 25px;
      }
      ~ li {
        padding: 25px 0px;
      }
      + li {
        margin-left: 25px;
      }
    }
    .primary {
      > li:last-child a {
        padding-bottom: 25px;
      }
      .show-dropdown > a {
        color: #E8D766;
      }
    }
    .expand-dropdown {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: inline-block;
      width: 40px;
      height: 40px;
      position: relative;
      margin-right: 15px;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      background: url(/img/interfaces/icons/menu-drop-down.svg) no-repeat (center / 75%);
      -webkit-transition: -webkit-transform 150ms;
      transition: -webkit-transform 150ms;
      transition: transform 150ms;
      transition: transform 150ms, -webkit-transform 150ms;
    }
    .show-dropdown .expand-dropdown {
      -webkit-transform: scaleY(-1);
      transform: scaleY(-1);
    }
    .primary .dropdown, .secondary .dropdown {
      width: 100%;
      text-align: left;
    }
    .primary .dropdown strong {
      font-weight: 700 !important;
      font-size: 1rem !important;
    }
  }
  .dropdown.side-by-side .dropdown-section strong a {
    font-weight: 700 !important;
    font-size: 1rem !important;
  }
}

@media (min-width: 1025px) {
  nav {
    li {
      position: relative;
      display: inline-block;
    }
    .primary a {
      display: inline-block;
      padding: 30px 15px;
      color: inherit;
    }
    .expand-dropdown {
      display: none !important;
    }
  }
}

@media (min-width: 1165px) {
  nav .primary a {
    padding: 45px 15px;
  }
}

@media (max-width: 1024px) {
  nav {
    li {
      .dropdown {
        -webkit-transition: opacity 150ms, max-height 150ms 150ms;
        transition: opacity 150ms, max-height 150ms 150ms;
      }
      &.show-dropdown .dropdown {
        max-height: 9999px;
        opacity: 1;
        -webkit-transition: opacity 150ms 150ms, max-height 150ms;
        transition: opacity 150ms 150ms, max-height 150ms;
      }
    }
    .secondary {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
      -padding: 0px 25px 25px;
      text-align: left;
      font-size: 1rem;
    }
  }
}

.dropdown {
  .dropdown-section {
    color: #FFF;
    vertical-align: text-top;
  }
  &.side-by-side .dropdown-section {
    padding: 25px;
  }
  .dropdown-section {
    + .dropdown-section {
      background-color: #808285;
      + .dropdown-section {
        background-color: #939598;
        + .dropdown-section {
          background-color: #A7A9AC;
        }
      }
    }
    a {
      display: block;
      padding: 25px;
      font-family: Montserrat;
      font-size: 0.875rem;
      line-height: 1;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &.side-by-side {
    .dropdown-section {
      a {
        padding: 0px;
        margin-top: 15px;
        font-family: "Fira Sans";
        font-size: 1rem;
        line-height: 1;
        font-weight: normal;
        text-transform: none;
      }
      strong a {
        margin-top: 0px;
        font-family: Montserrat;
        font-size: 0.875rem;
        line-height: 1;
        font-weight: 500;
      }
      + .dropdown-section {
        background-color: #939598;
        + .dropdown-section {
          background-color: #A7A9AC;
        }
      }
    }
    background-color: #808285;
  }
}

nav .secondary .dropdown a {
  display: block;
  width: auto;
  &:hover {
    color: #E8D766;
  }
}

@media (max-width: 1024px) {
  nav .secondary .dropdown a + a {
    padding-top: 0px;
  }
}

@media (min-width: 1025px) {
  nav {
    .secondary .spacer {
      padding-right: 13px;
      border-right: 2px solid currentColor;
    }
    li .dropdown {
      position: absolute;
      display: block;
      width: 300px;
      top: 100%;
      left: 50%;
      border-top: 1px solid #FFF;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 1000;
    }
    .secondary {
      .dropdown a + a {
        margin-top: 15px;
      }
      li .dropdown {
        left: 0px;
        top: 15px;
        -webkit-transform: none;
        transform: none;
        width: auto;
        padding: 20px;
        border-top: none;
      }
    }
    li {
      .dropdown.side-by-side {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 225px;
        width: 600px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
      }
      &:hover .dropdown:not(.dropdown-only-on-click), &.show-dropdown .dropdown.dropdown-only-on-click {
        max-height: 9999px;
        opacity: 1;
        -webkit-transition: opacity 150ms;
        transition: opacity 150ms;
      }
    }
    .primary li {
      &:hover, &.selected {
        color: #E8D766;
      }
    }
  }
  .dropdown {
    &.side-by-side .dropdown-section {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: inline-block;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 50%;
      a {
        height: 16px;
      }
    }
    .dropdown-section a:hover {
      color: #E8D766;
    }
  }
}

/*********************************************************************************
Apply page style
*********************************************************************************/
.apply-head {
  position: relative;
  margin-bottom: 65px;
  height: 220px;
  display: table;
  width: 100%;

  
  .logo-container {
    display: table-cell;
    vertical-align:middle; 
    text-align:center;
    padding-bottom: 40px;
  img {
    display: block;
    margin: 0 auto;
    width: 370px;
    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -39px;
      margin-left: -210px;
      width: auto;
    }
  }
} 
  .apply-logo {


    // position: absolute;
    // top: 50%;
    // left: 50%;
    // margin-top: -39px;
    // margin-left: -210px;
    // width: auto;
  }
}

@media (max-width:767px) {
  .apply-head {
    .logo-container {
      padding-bottom: 0;
      img {
        width: 250px;
      }
    }
  }
}

.apply-wrapper {
  max-width: 940px;
  margin: 0 auto;
  position: relative;

  .return {
    background: url(../img/apply/bg-return.png) no-repeat left center;
    padding-left: 30px;
    text-decoration: none;
    color: #fff;
    position: absolute;
    top: -110px;
    left: 0;
    z-index: 9999;
    text-transform: uppercase;
    font: 12px/16px droid sans;
  }
}

.hr {
  background: #d6d6d6;
  height: 1px;
  margin: 60px 0;
}

// @media (max-width: 767px) {
//   .apply-head {
//     height: 300px;
//     .apply-logo {
//       width: 250px;
//     }
//   }


// }