html,
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    font-size: 16px;
    line-height: 1.5;
    font-family: "Fira Sans", Arial, sans-serif;
    color: #333333;
}

@media(max-width: 1100px) {
    html,
    body {
        font-size: 14px;
    }
}

body.mce-content {
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1;
    margin: 0 0 10px;
    color: #00447E;
    font-weight: bold;
}

h1 {
    font-family: Oswald;
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: normal;
}

h2 {
    color: #00447E;
    font-size: 2.25rem;
}

.alternate h2 {
    position: relative;
    display: block;
    padding-bottom: 30px;
    margin-bottom: 30px;
    color: #00447C;
    font-family: "Fira Sans";
    font-size: 3.75rem;
    font-weight: 500;
    line-height: 1.25;
    text-transform: none;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.alternate h2:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 60px;
    background-color: #E8D766;
}

.blue h2,
.darker-blue h2 {
    color: inherit;
}

h3 {
    color: #848484;
    font-size: 1.875rem;
    font-weight: 500;
}

h3.emphasize {
    font-size: 1.75rem;
    line-height: 1.4;
    color: #00447e;
    font-style: italic;
    font-weight: normal;
}

.alternate h3 {
    color: #848484;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.25;
    text-transform: none;
}

.blue h3,
.darker-blue h3 {
    color: #E8D766;
    font-weight: 400;
}

h4 {
    color: #e8d766;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
    font-weight: 500;
}

h5 {
    font-size: 1.3125rem;
    font-weight: 500;
}

h6 {
    color: #444444;
    font-size: 1.125rem;
    font-weight: 500;
}

form {
    margin: 0;
}

@media(min-width: 1100px) {
    h1 {
        margin: 0 0 50px;
    }
    h2,
    h3,
    h4 {
        margin: 0 0 35px;
    }
}

@media(max-width: 1099px) {
    h1 {
        font-size: 2.65rem;
        margin-bottom: 25px;
    }
}

p {
    margin: 0px;
}

p+p,
ul+ul,
p+ul,
ul+p,
h1+p,
h2+p,
h3+p,
h4+p,
h5+p,
h6+p,
h1+ul,
h2+ul,
h3+ul,
h4+ul,
h5+ul,
h6+ul {
    margin-top: 15px;
}

p+h1,
p+h2,
p+h3,
p+h4,
p+h5,
p+h6,
ul+h1,
ul+h2,
ul+h3,
ul+h4,
ul+h5,
ul+h6 {
    margin-top: 50px;
    margin-top: 40px;
}

b,
strong {
    font-weight: 500;
}

ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.center {
    text-align: center;
}

.nowrap {
    white-space: nowrap;
}

.underline {
    text-decoration: underline;
}

.trademark {
    display: inline-block;
    position: relative;
    width: 1rem;
    font-size: 0px;
    line-height: 1;
    vertical-align: text-top;
    height: 100%;
}

.trademark:after {
    content: 'tm';
    position: absolute;
    top: 0.375rem;
    top: 0.375rem;
    text-transform: uppercase;
    font-size: .5rem;
}

nav .trademark:after {
    top: 0;
}

.padded {
    padding: 20px;
}

.padded.alternate {
    color: #444444;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
    margin-bottom: 20px;
}

.content ul {
    list-style: disc;
    margin: 0px;
    padding: 0px 0px 0px 20px;
}

.content ol {
    margin: 0px;
    padding: 0px 0px 0px 20px;
}

.content li {
    margin-bottom: 5px;
}

.content table {
    border-collapse: collapse;
    border: 1px solid #959595;
    margin-bottom: 20px;
}

.content table th,
.content table td {
    padding: 15px;
    border: 1px solid #959595;
    line-height: 1.1;
    font-size: 1rem;
}

.content table th {
    padding: 15px 25px;
    color: #00477C;
    font-family: Montserrat;
    text-transform: uppercase;
    letter-spacing: .1em;
    background-color: #F2F2F2;
    text-align: left;
    white-space: nowrap;
}

.content .legal {
    color: #848484;
    font-size: 0.875rem;
}

.content .button {
    margin-top: 50px;
}

.content a {
    text-decoration: underline;
    color: #00447E;
}

.content .button {
    text-decoration: none;
}

@media(min-width: 768px) {
    .content .button+.button,
    .banner .button+.button {
        margin-left: 20px;
    }
}

@media(max-width: 767px) {
    .content table th,
    .content table td {
        display: block;
        border-width: 0px;
        border-bottom-width: 1px;
        padding: 15px;
    }
    .content table tr:last-child th:last-child,
    .content table tr:last-child td:last-child {
        border-bottom-width: 0px;
    }
    .content .button {
        margin-top: 25px;
    }
}
